import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"

import authStore from "../stores/auth"

import styled from "styled-components"
import { get } from "lodash"

const Container = styled.div`
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
`

export default function LoginError() {
  const error = get(authStore, "state.error")

  useEffect(() => {
    if (!error) {
      navigate(localStorage.targetUrl || "/proxies")
    }
  }, [])

  if (!error) {
    return null
  }

  return (
    <Container>
      <h2>Oops!</h2>
      <p>{error.errorDescription || "Something bad happened!"}</p>
      <p>
        <a href="#retry" onClick={() => authStore.login()}>
          Click here to retry
        </a>
      </p>
      <p>
        <a
          href="#login-again"
          onClick={() => {
            authStore.logout(() =>
              authStore.login({
                prompt: "login",
              })
            )
          }}
        >
          Click here to login with another account
        </a>
      </p>
      <p>
        <Link to="/contact-us">Contact Us</Link>
      </p>
    </Container>
  )
}
